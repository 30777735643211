<template>
    <div id="app">
        <Banner />
        <Projects />
    </div>
</template>

<script>
import Banner from '../components/Banner.vue';
import Projects from '../components/Projects.vue';

export default {
    components: {
        Banner,
        Projects,
    }
}
</script>

<style lang="scss" scoped>
#app{
    display: block;
    width: 100%;
}
</style>