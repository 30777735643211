export default {
/*
 
       ____  _______     __
      |  _ \| ____\ \   / /
      | | | |  _|  \ \ / / 
      | |_| | |___  \ V /  
      |____/|_____|  \_/   
                           
 
*/
    // IO: 'http://localhost:4000',
    // API: 'http://localhost:4000/api',

/*
 
       ____  ____   ___  ____  
      |  _ \|  _ \ / _ \|  _ \ 
      | |_) | |_) | | | | | | |
      |  __/|  _ <| |_| | |_| |
      |_|   |_| \_\\___/|____/ 
                               
 
*/

    IO: 'https://giovanni.scarpellino.eu',
    API: 'https://giovanni.scarpellino.eu/api',
}