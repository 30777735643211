import Vue from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import VueI18n from 'vue-i18n';
import { library } from '@fortawesome/fontawesome-svg-core';
import { faEdit, faBell } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';

library.add(faEdit, faBell);
Vue.component('font-awesome-icon', FontAwesomeIcon);

Vue.config.productionTip = false

Vue.use(VueI18n);

function embedVideo(id){
    return `<div class='embed'><iframe width='100%' height='350' src='https://www.youtube.com/embed/${id}?controls=0' title='YouTube video player' frameborder='0' allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture' allowfullscreen></iframe></div>`;
}

const i18n = new VueI18n({
    locale: 'en',
    messages: {
        'fr': {
            projects: {
                giovanni: "Bonjour 😁 <br> N'héistez pas à me poser une question dans cette conversation, je vous réponderai avec plaisir 🙂.",
                esdi: "Ce projet à eu lieux pendant un stage du DUT Informatique de Belfort durant <b>4 mois</b>. <br> Le principe ? Reproduire l'environnement de l'entreprise en 3D avec le moteur de jeu Unity afin de voir en tout réel le truc... <br> Voici la vidéo du projet si vous voulez en voir plus 🙂 <br><div class='embed'><iframe width='100%' height='350' src='https://www.youtube.com/embed/6KKGnPRS_7w?controls=0' title='YouTube video player' frameborder='0' allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture' allowfullscreen></iframe></div>",
                game_jam: "Jeu vidéo avec du Pathfinding pour déplacement de personnages.<br><div class='embed'><iframe width='100%' height='350' src='https://www.youtube.com/embed/irUHvsfppvU?controls=0' title='YouTube video player' frameborder='0' allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture' allowfullscreen></iframe></div><br><a target='_blank' href='/gamejam'>Si tu veux essayer, clique ici!</a>",
                zestfully: "Guide des meilleurs restaurants pour toutes les occasions. Premièrement à Londres, puis à travers le monde! <br> <a target='_blank' href='https://zestfully.co'>Rendez vous sur le site ici!</a>",
                bretzel_games: "Plusieurs jeux de plateau refait en web et en temps réel. <br> <a target='_blank' href='https://bretzel.games/'>Rendez vous sur le site ici!</a>",
                qrun: "Application de chasse au trésor. L'utilisateur scanne un QR code et l'application lui donne un indice et indique à combien de mètre est l'objet à récupérer. PS: Merci à <b>@PENELON Pierre</b> pour sa belle voix.<br><div class='embed'><iframe width='100%' height='350' src='https://www.youtube.com/embed/7h78NaebtZ8?controls=0' title='YouTube video player' frameborder='0' allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture' allowfullscreen></iframe></div>",
                gogoresto: "Go go resto est un projet réalisé avec 4 autres personnes durant le DUT Informatique de Belfort. Le but ? Trouver les meilleurs restaurants à proximité de l'utilisateur grâce à une application.<br><div class='embed'><img src='/assets/img/gogoresto.png'></div>",
                syncodeo: "Plateforme web permettant de regrouper code et vidéo dans une même plateforme dans le but de rendre l'apprentissage du code plus accessible.",
                playdate_snake: `Un jeu Snake développé en Lua pour la console de jeu <a target='_blank' href='https://play.date'>playdate</a>. ${embedVideo("smG5pqkd4dU")}`,
            }
        },
        'en': {
            projects: {
                giovanni: `Hello 👋 <br> 
                You can speak with me here ! <br>
                Don't worry, it's private 🔒 😁. <br>`,
                esdi: `Interactive app powered by Unity displayed in real time employe informations of company in an 3D environment. <br>
                ${embedVideo("6KKGnPRS_7w")}`,
                game_jam: `48h Gamejam : Procedurally generated world with A* path finding implementation for player and ennemies movement. <br>
                <a target='_blank' href='/gamejam'>You can test it here!</a> <br>
                ${embedVideo("irUHvsfppvU")}`,
                zestfully: `Guide which select only best restaurants for best moments. First in London, then in all over the world! <br> 
                <a target='_blank' href='https://zestfully.co'>Go to zestfully.co!</a>
                ${embedVideo("087iTS3aLnM")} <br>
                Zestfully have also a Backoffice:
                ${embedVideo("P1-v3D3AdqE")}`,
                bretzel_games: "Some board game in real time. <br> <a target='_blank' href='https://bretzel.games/'>Go to Bretzel Games!</a>",
                qrun: `Mobile App of treasure hunt. User scan a Qr code and the app give him an advice and tell  if the user is near of the hunted object or not. <br>
                ${embedVideo("7h78NaebtZ8")}`,
                gogoresto: "Go go resto is a project carried out with 4 other people during the DUT. This project aims to find best restaurants near the user through an Android application. <br><div class='embed'><img style='width: 100%' src='/gogoresto.png'></div>",
                syncodeo: "A web application that aims to regroup codes and videos in the same platform to learn code easier.",
                playdate_snake: `A Snake game developed on Lua for the <a target='_blank' href='https://play.date'>playdate</a> console. ${embedVideo("smG5pqkd4dU")}`,
            }
        },
    }
});

new Vue({
    router,
    store,
    i18n,
    render: h => h(App)
}).$mount('#app')