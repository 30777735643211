<template>
    <div id="projects" class="projects">
        <div class="navigator">
            <div class="actions">
                <div class="close"></div>
                <div class="minus"></div>
                <div class="zoom"></div>
            </div>

            <div class="content">
                <div class="left">

                    <div class="divider">
                        <span>🔓 <span class="text">Private conversation</span></span>
                    </div>

                    <ul v-if="!password && projects[0]">
                         <li :key="projects[0].title" :class="[{ active: activeProject === projects[0], notified: notifications[projects[0].key] }]" @click="activeProject = projects[0]" v-show="!projects[0].hide">
                            <Avatar :avatar="projects[0].avatar" :color="projects[0].avatarColor" />
                            <span>{{ projects[0].title }}</span>
                            <span class="notifications" v-if="notifications[projects[0].key]"><font-awesome-icon icon="bell" /></span>
                        </li>
                    </ul>


                    <ul v-else>
                         <li v-for="project of projects.filter(project => project.conversation)" :key="project.title" :class="[{ active: activeProject === project, notified: notifications[project.key] }]" @click="activeProject = project" v-show="!project.hide">
                            <Avatar :avatar="project.avatar" :color="project.avatarColor" />
                            <span>{{ project.title }}</span>
                            <span class="notifications" v-if="notifications[project.key]"><font-awesome-icon icon="bell" /></span>
                        </li>
                    </ul>

                    <div class="search divider">
                        <span>🌍 <span class="text">Public conversation</span></span>
                        <input type="text" v-model="form.search" placeholder="Search for a project..." />
                    </div>

                    <ul>
                        <li v-for="project of projects.slice(this.password ? 0 : 1, projects.length).filter(project => !project.conversation)" :key="project.title" :class="[{ active: activeProject === project, notified: notifications[project.key] }]" @click="activeProject = project" v-show="!project.hide">
                            <Avatar :avatar="project.avatar" :color="project.avatarColor" />
                            <span>{{ project.title }}</span>
                            <span class="notifications" v-if="notifications[project.key]"><font-awesome-icon icon="bell" /></span>
                        </li>
                    </ul>
                </div>

                <div class="conversation" v-if="activeProject">
                    <div class="title">
                        <Avatar :avatar="activeProject.avatar" :color="activeProject.avatarColor"  />
                        <span>{{ activeProject.title }} </span>
                        <span v-if="activeProject.logos && activeProject.logos.length">&nbsp;|&nbsp;</span>
                        <img v-for="(logo, index) in activeProject.logos" :key="index" height="20" :src="require('../assets/img/logos/' + logo + '.png')" :alt="logo" :title="logo">
                    </div>

                    <div class="messages">

                        <div class="message-wrapper" v-for="(message, index) of activeProject.messages" :key="index">
                            <template v-if="message.me">
                                <div class="message">
                                    <template>
                                        <Avatar :color="'rgb(37, 128, 205)'"/>
                                        <span> Giovanni </span>
                                        <span class="createdAt"> {{ formatDate(new Date(message.createdAt)) }} </span>
                                    </template>
                                </div>

                                <div class="message-content" v-html="message.content"></div>
                            </template>

                            <template v-else>
                                <div class="message">
                                    <template>
                                        <Avatar :avatar="message.User.avatar" :color="message.User.avatarColor || 'green'"/>
                                        <form @submit.prevent="sendNickname" v-if="message.User.id === userId && form.nickname.edit">
                                            <input type="text" placeholder="Pseudo" v-model="form.nickname.value" autofocus>
                                        </form>
                                        <span v-else>{{ password ? message.User.nickname || message.User.id : message.User.nickname || 'Vous' }} <font-awesome-icon class="edit" @click="editNickname(message.User.nickname)" v-if="message.User.id === userId" icon="edit" /></span>
                                        <span class="createdAt"> {{ formatDate(new Date(message.createdAt)) }} </span>
                                    </template>
                                </div>

                                <div class="message-content" v-html="message.content"></div>
                            </template>
                        </div>

                        <div class="message-wrapper" v-if="!activeProject.conversation">
                            <div class="message">
                                <Avatar :avatar="activeProject.avatar" :color="activeProject.avatarColor"/>
                                <span>{{ activeProject.title }}</span>
                            </div>

                            <div class="message-content" v-html="$t('projects.' + activeProject.key)"></div>

                            <!-- <div class='email-input' v-if="activeProject.key === 'giovanni'">
                                <input style='width: 300px;' v-model='form.email' type='text' placeholder='Your email to be notified...'>
                                <img v-if="form.email" :src='loading.email ? "/spinner-solid.svg" : "/check-solid.svg"' :class="['email-loading', { 'spin': loading.email }]" />
                            </div> -->
                        </div>

                    </div>

                    <form class="input" @submit.prevent="send">
                        <input type="text" v-model="form.message" placeholder="A question ? 🤔" />
                        <button style="display: none;" type="submit"></button>
                    </form>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import Avatar from './Avatar.vue';
import Axios from '../plugin/axios';
import Config from '../config';
import io, { Socket } from 'socket.io-client';
import moment from 'moment';
import { initializeApp } from 'firebase/app';
import { getMessaging, getToken, onMessage } from 'firebase/messaging';

let emailTimeout;

export default {
    async beforeMount(){
        this.userId = Number(localStorage.getItem('userId')) || '';

        await this.refreshAll();

        this.activeProject = this.projects[0];

        this.registerFCM();
        this.registerSocket();
        this.registerNotifications();
        this.registerProjects();

        if(this.userId)
            this.registerConversation();

        if(this.password || this.userId)
            this.registerLogin();
    },
    components: {
        Avatar,
    },
    data() {
        return {
            /** @type {Socket} */
            socket: undefined,
            fcmToken: undefined,
            form: {
                email: undefined,
                search: undefined,  
                message: undefined,
                nickname: {
                    edit: false,
                    value: undefined,
                },
            },
            user: undefined,
            userId: undefined,
            notifications: {},
            activeProject: undefined,
            projects: [],
            loading: {
                email: false,
            }
        };
    },
    methods: {
        async registerFCM(){
            const app = initializeApp({
                projectId: "site-gio",
                messagingSenderId: "308696927982",
                apiKey: "AIzaSyCp2oflepmzh_izJueitbWW6Pyy02pqnuU",
                appId: "1:308696927982:web:2c9d61b2330d180bf7edff",
            });

            const messaging = getMessaging(app);

            const fcmToken = await getToken(messaging, { vapidKey: 'BOLhOv-4o4JYSxeqM6kuxHSIt1K__Uf2rwDbCtx_LuWNWVTIj1A7VAH6LWTo8Z-uM2ZHXEKa-zP7U2YjouI2bso' });

            this.fcmToken = fcmToken;

            if(this.userId)
                Axios.post(`/users/${this.userId}/fcmToken`, { token: fcmToken });

            if(this.password)
                Axios.post(`/admin/fcmToken?password=${this.password}`, { token: fcmToken });
        },
        registerProjects(){
            this.socket.on('projects.messages', this.appendNewMessage);
        },
        registerConversation(){
            this.socket.on(`projects.messages.${this.userId}`, this.appendNewMessage);
        },
        registerSocket(){
            this.socket = io(Config.IO);
        },
        registerLogin(){
            this.socket.emit('login', this.password || this.userId);
        },
        async registerNotifications(){
            if(!this.userId && !this.password){
                for(let project of this.projects)
                    this.$set(this.notifications, project.key, true);
            }
            else{
                this.socket.on('connect', async () => {
                    const [
                        { data: notifications },
                        { data: user }
                    ] = await Promise.all([
                        Axios.get(`/projects/notifications?password=${this.password}`),
                        this.password ? Axios.get(`/users/adminLastConnection?password=${this.password}`) : Axios.get(`/users/${this.userId}`)
                    ]);

                    if(user){
                        this.user = user;

                        if(user.email)
                            this.form.email = user.email;
                    }

                    for(let notification of notifications){
                        if(user.lastConnection && new Date(notification.lastMessage) > new Date(user.lastConnection)){
                            this.$set(this.notifications, notification.ProjectKey, true);
                        }
                    }
                });
            }
        },

        appendNewMessage(message){
            let project = this.projects.find(project => project.key === message.ProjectKey);

            if(!project){
                project = {
                    key: message.ProjectKey,
                    title: message.User.nickname || message.User.id,
                    messages: [],
                    conversation: true,
                    avatarColor: message.User.avatarColor || 'green',
                }
                this.projects.push(project);
            }

            project.messages.unshift(message);
            if(this.activeProject.key !== project.key)
                this.$set(this.notifications, project.key, true);
        },

        formatDate(date){
            return moment(date).format('L') + ' ' + moment(date).format('LT');;
        },

        async refreshAll(){
            const { data: projects } = await Axios.get(`/projects?userId=${this.userId}&password=${this.password}`);
            this.projects = projects;
        },
        async send(){
            if(this.activeProject.conversation){
                const { data: message } = await Axios.post(`/users/${this.activeProject.key}/replies?password=${this.password}`, {
                    content: this.form.message,
                });
                
                if(!this.userId && !this.password)
                    this.activeProject.messages.unshift(message);

                this.form.message = '';
                this.userId = message.UserId;
            }
            else{
                const { data: message } = await Axios.post(`/projects/${this.activeProject.key}/messages?userId=${this.userId}&password=${this.password}`, {
                    content: this.form.message,
                });

                if(!this.userId && !this.password)
                    this.activeProject.messages.unshift(message);
                    
                this.form.message = '';
                this.userId = message.UserId;
            }
        },
        async editNickname(nickname){
            this.form.nickname.value = nickname || 'Vous';
            this.form.nickname.edit = true
        },
        async sendNickname(){
            await Axios.put(`/users/${this.userId}`, { nickname: this.form.nickname.value });
            const activeIndex = this.projects.indexOf(this.activeProject);
            await this.refreshAll();
            this.activeProject = this.projects[activeIndex];
            this.form.nickname.edit = false;
        }
    },
    watch: {
        'form.search'(search){
            if(!search){
                for(let project of this.projects)
                    this.$set(project, 'hide', false);
            }else{
                for(let project of this.projects)
                    this.$set(project, 'hide', !project.title.toLowerCase().includes(search.toLowerCase()));
            }
        },
        activeProject(project){
            this.$set(this.notifications, project.key, false);
        },
        userId(userId, previous){
            if(!previous && userId && this.socket){
                localStorage.setItem('userId', userId);
                this.registerLogin();
                this.registerConversation();
            }
        },
        async 'form.email'(email){
            if(email === this.user.email)
                return;

            this.loading.email = true;

            if(emailTimeout){
                clearTimeout(emailTimeout);
                emailTimeout = undefined;
            }

            emailTimeout = setTimeout(async () => {
                const { data: userId } = await Axios.post(`/users/email?userId=${this.userId}`, { email });
                this.userId = userId;
                localStorage.setItem('userId', userId);
                this.loading.email = false;
            }, 1000);
        }
    },
    computed: {
        password: {
            set(password){
                localStorage.setItem('password', password);
            },
            get(){
                return localStorage.getItem('password') || '';
            }
        }
    }
};
</script>

<style lang="scss" scoped>
$normal: rgb(42, 43, 47);
$border-radius: 15px;

@media (max-width: 710px) { 
    body{
        .projects{
            min-height: 100vh;

            .navigator{
                margin: 0px;
                border-radius: unset;
                min-width: 100%;

                .left{
                    width: 65px;
                    overflow-x: hidden;
                    overflow-y: auto;
                    text-align: center;
                    
                    .text{
                        display: none;
                    }

                    ul {
                        li{
                            .notifications{
                                position: absolute;       
                                top: 50%;
                                transform: translate(-50%, -50%);
                                left: 15px;
                            }
                        }
                    }
                }

                .content{
                    height: 100vh;
                }
            }
        }
    }
}

.projects {
    min-height: 80vh;
    background: #fff;

    .navigator {
        box-shadow: 5px 5px 20px rgba(0, 0, 0, 0.5);
        -webkit-box-shadow: 5px 5px 20px rgba(0, 0, 0, 0.5);
        -moz-box-shadow: 5px 5px 20px rgba(0, 0, 0, 0.5);

        border-radius: $border-radius;

        .actions {
            padding: 10px;

            .close,
            .minus,
            .zoom {
                height: 15px;
                width: 15px;
                border-radius: 50%;
                display: inline-block;
                margin-right: 10px;
            }

            .close {
                background: #ed6a5e;
            }

            .minus {
                background: #f5bf4f;
            }

            .zoom {
                background: #61c554;
            }
        }

        max-width: 80%;
        margin: 5em auto;
        background: $normal;

        .content {
            display: flex;
            background: darken($normal, 3);
            height: 80vh;

            .left {
                overflow: auto;
                max-width: 220px;

                .search {
                    input {
                        margin-top: 1em;
                        height: 20px;
                    }
                }

                .divider{
                    padding: 12.3px;
                    border-bottom: 2px solid lighten($normal, 3);
                    color: rgb(140, 140, 140);
                    font-size: 15px;    
                }

                ul {
                    padding: 0px;
                    margin: 0px;
                    min-width: 200px;

                    li {
                        position: relative;
                        display: flex;
                        align-items: center;
                        cursor: pointer;
                        list-style: none;
                        padding: 15px;
                        color: rgb(140, 140, 140);

                        &.active {
                            background: $normal;
                            color: #fff;
                        }

                        &.notified {
                            color: #fff;
                        }

                        .notifications{
                            position: absolute;       
                            top: 50%;
                            transform: translate(-50%, -50%);
                            right: 10px;
                            border-radius: 50%;
                            height: 15px;
                            width: 15px;
                            font-size: 1em;
                            font-weight: bold;
                            text-align: center;
                            color: #fff;
                        }

                        .trash{
                            position: absolute;       
                            top: 50%;
                            transform: translate(-50%, -50%);
                            right: 20px;
                            border-radius: 50%;
                            height: 15px;
                            width: 15px;
                            font-size: 1em;
                            font-weight: bold;
                            text-align: center;
                            color: #fff;
                        }
                    }
                }
            }

            .conversation {
                display: flex;
                flex-direction: column;
                flex: 1;
                background: lighten($normal, 3);
                color: #fff;
                overflow: auto;

                .title {
                    display: flex;
                    align-items: center;
                    padding: 15px;
                    border-bottom: 2px solid darken($normal, 3);
                    overflow: auto;

                    img{
                        margin-right: 10px;
                    }
                }

                .messages {
                    flex: 1;
                    display: flex;
                    flex-direction: column-reverse;
                    padding: 15px;
                    max-height: auto;
                    overflow: auto;

                    input{
                        width: auto;
                    }

                    .message-wrapper{
                        .message{
                            display: flex;
                            align-items: center;
                            font-weight: bold;

                            .createdAt{
                                margin-left: 0.3em;
                                font-size: 0.7em;
                                opacity: 0.5;
                                padding-top: 4px;
                            }

                            .edit{
                                cursor: pointer;
                            }
                        }

                        .message-content{
                            margin: 0;
                            padding-left: 50px;
                            line-height: 1.6em;
                            padding-right: 15px;
                        }
                    }

                }

                .input {
                    padding: 15px 25px 20px 15px;
                }
            }
        }
    }
}
</style>

<style lang="scss">
$normal: rgb(42, 43, 47);

.message-content{
    .embed{     
        background: darken($normal, 4);
        padding: 15px;
        border-radius: 15px;
    }

    a {
        color: rgb(37, 128, 205);
    }
}
</style>