<template>
    <div class="banner">
        <div id="particles-js" class="particules"></div>

        <div class="banner-title">
            <div class="logo">
                <img :src="require('../assets/img/keyboard.png')" alt="keyboard" class="keyboard">
                <img :src="require('../assets/img/screen.png')" alt="screen" :class="['screen', { open }]">
                <div class="text" v-html="logo"></div>
            </div>

            <div class="actions">
                <button class="button ghost" @click="scrollToProjects"> PROJECTS </button>
                <a class="button ghost" target="_blank" href="https://giovanniscarpellino.github.io/cv"> CV </a>
            </div>
        </div>

    </div>
</template>

<script>
import particules from '../assets/particules.json';

export default {
    mounted() {
        require("particles.js");
        this.$nextTick(() => particlesJS("particles-js", particules));

        setTimeout(() => {
            this.open = true;

            setTimeout(async () => {
                const textes = ["> SCARPELLINO", "> Giovanni", "> ", "> _"];
                for(let index = 0; index < textes.length; index++){
                    for(let letter of textes[index]){
                        await new Promise(resolve => setTimeout(resolve, 50));
                        this.logo += letter;
                    }
                    await new Promise(resolve => setTimeout(resolve, 100));
                    if(index < textes.length - 1)
                        this.logo += '<br>';
                }
                
                setInterval(async () => {
                    if(this.logo[this.logo.length - 1] === '_')
                        this.logo = this.logo.slice(0, -1);
                    else
                        this.logo += '_';
                }, 500);
            }, 1100);
        }, 1000);
    },
    data(){
        return {
            logo: '',
            open: false,
        }
    },
    methods: {
        scrollToProjects(){
            const element = document.querySelector('#projects');
            element.scrollIntoView({ behavior: 'smooth' });
        }
    }
};
</script>

<style lang="scss" scoped>
.banner{
    height: 100vh;
    background: black;

    #particles-js{
        height: 100%;
    }

    .banner-title{
        position: absolute;
        top: 20%;
        left: 50%;
        transform: translateX(-50%);
        
        .logo{
            width: 350px;
            height: 200px;
            margin: auto;
            pointer-events: none;
            -webkit-touch-callout: none; /* iOS Safari */
            -webkit-user-select: none; /* Safari */
            -khtml-user-select: none; /* Konqueror HTML */
            -moz-user-select: none; /* Old versions of Firefox */
            -ms-user-select: none; /* Internet Explorer/Edge */
            user-select: none; /* Non-prefixed version, currently */

            .keyboard{
                z-index: 1;
            }

            .screen{
                transform-origin: bottom center;
                transform: rotate3d(1, 0, 0, 90deg);
                transition: transform 1s ease-in-out;
                z-index: 0;

                &.open{
                    transform: rotate3d(1, 0, 0, 0deg);
                }
            }

            img{
                position: absolute;
                left: 0px;
                top: 0px;
                width: 100%; 
            }

            .text{
                position: absolute;
                height: 100%;
                width: 100%;
                padding-top: 25px;
                padding-left: 65px;
                font-size: 26px;
                line-height: 1.2em;
                color: white;
                z-index: 1;
                font-family: 'Aldrich';
            }
        }

        .actions{
            display: flex;
            justify-content: space-evenly;
            margin-top: 15px;

            .button{
                min-width: 75px;
            }
        }
    }
}
</style>
