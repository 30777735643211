<template>
    <div class="avatar" :style="{
        'background-image': avatar ? 'url(' + require('../assets/img/projects/' + avatar) + ')' : undefined,
        'background-color': color
    }"></div>
</template>

<script>
export default {
    props: {
        avatar: String,
        color: String,
    },
}
</script>

<style lang="scss" scoped>
 .avatar {
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    height: 35px;
    width: 35px;
    // background-color: rgb(37, 128, 205);
    border-radius: 50%;
    margin-right: 15px;
}
</style>